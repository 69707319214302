/**
 * Polyfills Declarative Shadow DOM if the browser lacks support.
 */
export function polyfillDeclarativeShadowDOM(element: Element) {
  const template = element.querySelector("template");

  if (template) {
    element.attachShadow({ mode: "open" });
    element.shadowRoot?.append(template.content);
    template.remove();
  }
}
