import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";

import {
  MENU_CLOSE_EVENT_NAME,
  LANGUAGE_TOGGLE_EVENT_NAME,
} from "./global-header.constants";

@customElement("bp-global-header-menu-controls")
export class GlobalHeaderMenuControls extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    .menu-controls {
      display: flex;
      align-items: center;
      gap: var(--size-1-5);
      padding-block: var(--size-2-5);
      padding-inline: var(--size-2);
    }

    .button {
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      color: currentColor;
      cursor: pointer;
      display: inline-flex;
      font-family: var(--font-sans);
      gap: var(--size-1);
      justify-content: space-between;
      line-height: var(--line-height-none);
      margin: 0;
      padding: var(--size-0-5);
      text-align: start;
      text-decoration: none;
      -webkit-user-select: none;
      user-select: none;
    }

    [role="separator"] {
      height: var(--size-3);
      width: 1px;
      background-color: var(--color-neutral-20);
    }
  `;

  @property({ attribute: "href-bible-reader" })
  hrefBibleReader: string = "";

  @property({ attribute: "label-bible-reader" })
  labelBibleReader: string = "";

  @property({ attribute: "label-language-picker" })
  labelLanguagePicker: string = "";

  @property({ attribute: "label-close" })
  labelClose: string = "";

  handleCloseClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(MENU_CLOSE_EVENT_NAME, {
        composed: true,
      }),
    );
  };

  handleLanguageClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(LANGUAGE_TOGGLE_EVENT_NAME, {
        composed: true,
        detail: {
          $trigger: e.currentTarget,
        },
      }),
    );
  };

  render() {
    return html`
      <div class="menu-controls">
        ${this.hrefBibleReader
          ? html`
              <a
                class="button"
                aria-label="${this.labelBibleReader}"
                href="${this.hrefBibleReader}"
              >
                <bp-icon icon="book-open"></bp-icon>
              </a>
            `
          : nothing}

        <button
          class="button"
          aria-label="${this.labelLanguagePicker}"
          @click="${this.handleLanguageClick}"
        >
          <bp-icon icon="globe"></bp-icon>
        </button>

        <div role="separator"></div>

        <button
          class="button"
          aria-label="${this.labelClose}"
          @click="${this.handleCloseClick}"
        >
          <bp-icon icon="xmark" size="lg"></bp-icon>
        </button>
      </div>
    `;
  }
}
