import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("bp-global-header-language-picker-label")
export class GlobalHeaderPromo extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    .label {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      gap: var(--size-1);
    }

    .label > span:first-of-type {
      font-weight: var(--font-weight-normal);
    }

    .label > bp-icon {
      margin-inline-start: auto;
    }
  `;

  @property()
  alt? = undefined;

  @property({ type: Boolean })
  selected? = undefined;

  render() {
    return html`
      <div class="label">
        <span ?aria-hidden="${!!this.alt}"><slot></slot></span>

        ${this.alt ? html` <span>${this.alt}</span> ` : nothing}
        ${this.selected ? html` <bp-icon icon="check"></bp-icon> ` : nothing}
      </div>
    `;
  }
}
