/**
 * Creates a debounced function that is only invoked after
 * the wait interval.
 */
export function debounce<T extends (...args: any) => any>(
  func: T,
  wait: number = 100,
) {
  let timer: NodeJS.Timeout;
  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, wait);
  };
}
