import { css } from "lit";

export const styles = css`
  :host {
    --global-header-max-width: calc(var(--size-1) * 276);
    --global-header-search-max-width: calc(var(--size-1) * 225);
    --global-header-container-width: var(--size-45);
    --global-header-container-background-color: var(--color-white);
    --global-header-logo-width: calc(var(--size-1) * 17); // 136px
    --global-header-menu-tx-opened: 0;
    --global-header-menu-tx-closed: -200px;
    --global-header-menu-ty-opened: 0;
    --global-header-menu-ty-closed: 0;
    --global-header-submenu-tx-opened: 0;
    --global-header-submenu-tx-closed: 100vw;
    --global-header-submenu-ty-opened: 0;
    --global-header-submenu-ty-closed: 0;
    --global-header-submenu-width: 100%;
    --global-header-search-ty-opened: 0;
    --global-header-search-ty-closed: -180px;

    display: block;
    transition: opacity var(--duration-normal) var(--ease-in);
  }

  :is(a, button) {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    color: currentColor;
    cursor: pointer;
    display: inline-flex;
    font-family: var(--font-sans);
    gap: var(--size-1);
    justify-content: space-between;
    line-height: var(--line-height-none);
    margin: 0;
    padding: var(--size-0-5);
    text-align: start;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
  }

  a:has(bp-logo) {
    display: block;
    margin-block-start: calc(var(--size-1) + 1px);
    padding: var(--size-0-5);
    position: relative;
    z-index: var(--z-10);
  }

  .bypass {
    padding: var(--size-2);
    display: block;
    text-align: center;
  }

  .bypass:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  header {
    display: flex;
    max-width: var(--global-header-max-width);
    padding-inline: var(--size-3);
    padding-block: var(--size-2);
    margin-inline: auto;
    justify-content: space-between;
    align-items: center;
    height: var(--size-9);
    position: relative;
  }

  .container {
    position: absolute;
    width: calc(100% - var(--size-1) * 2);
    top: var(--size-2);
    right: var(--size-1);
    z-index: var(--z-80);
    opacity: 0;
    overflow: hidden;
    box-shadow: var(--shadow-lg);
    background-color: var(--global-header-container-background-color);
    border-radius: var(--radius-lg);
    grid-template-rows: auto 1fr auto;
    display: grid;
  }

  nav {
    position: relative;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .container :is(.main-menu, bp-global-header-submenu) {
    box-sizing: border-box;
    padding-block-start: var(--size-6);
    padding-block-end: var(--size-6);
    padding-inline: var(--size-3);
  }

  .main-menu {
    min-height: var(--global-header-menu-min-height);
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
  }

  .container bp-global-header-submenu {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--global-header-submenu-width);
    opacity: 0;
    transform: translate3d(
      var(--global-header-submenu-tx-closed),
      var(--global-header-submenu-ty-closed),
      0
    );
  }

  .main-menu :is(a, button),
  bp-global-header-submenu :is(a, button) {
    width: 100%;
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-semibold);
    padding: var(--size-2) var(--size-1);
    border-radius: var(--radius-md);
  }

  bp-global-header-submenu :is(a, button) span {
    font-weight: var(--font-weight-normal);
  }

  bp-global-header-menu-controls {
    justify-self: end;
  }

  bp-global-header-user-menu {
    position: relative;
    padding-block-start: var(--size-2);
  }

  bp-global-header-user-menu::before {
    content: "";
    position: absolute;
    width: var(--size-8);
    height: 1px;
    inset-inline-start: var(--size-4);
    inset-block-start: 0;
    background-color: var(--color-neutral-20);
  }

  .container nav + bp-global-header-submenu {
    top: var(--size-9);
  }

  bp-global-header-search {
    position: fixed;
    inset-inline: 0;
    inset-block-start: 0;
    z-index: var(--z-80);
    transform: translate3d(0, var(--global-header-search-ty-closed), 0);
    overscroll-behavior: contain;
    max-height: 100svh;
    overflow-y: auto;
  }

  bp-global-header-language-picker-label {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    inset: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: pointer;
    background-color: rgb(0 0 0 / 0.2);
    transform: translateZ(0);
    z-index: var(--z-70);
  }

  .simplified {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-2);
    margin-inline-start: var(--size-2);
    color: var(--color-gray);
  }

  .simplified-tagline {
    font-size: var(--font-size-lg);
  }

  .simplified > a {
    display: none;
    font-weight: var(--font-weight-semibold);
  }

  @media (min-width: 480px) {
    :host {
      --global-header-submenu-tx-closed: var(--global-header-container-width);
    }

    a:has(bp-logo) {
      z-index: unset;
    }

    .container {
      width: var(--global-header-container-width);
      inset-inline-start: auto;
      min-height: calc(100svh - var(--size-4));
    }

    .backdrop {
      visibility: visible;
      pointer-events: initial;
    }
  }

  /* Desktop */
  @media (min-width: 1024px) {
    :host {
      --global-header-container-background-color: transparent;
      --global-header-menu-tx-opened: 0;
      --global-header-menu-tx-closed: 0;
      --global-header-submenu-tx-opened: -50%;
      --global-header-submenu-tx-closed: -50%;
      --global-header-submenu-ty-closed: var(--size-3);
      --global-header-submenu-width: 860px;
      --global-header-search-ty-closed: -220px;
    }

    #language-submenu {
      --global-header-submenu-tx-opened: 0;
      --global-header-submenu-tx-closed: 0;
      top: var(--size-11);
      right: var(--size-4);
      left: auto;
    }

    header {
      display: grid;
      grid-template-columns: auto 1fr auto;
      padding-inline: var(--size-4);
    }

    .container {
      display: flex;
      justify-content: center;
      position: static;
      min-height: unset;
      max-width: unset;
      width: auto;
      opacity: 1;
      box-shadow: unset;
      background-color: unset;
      border-radius: unset;
      overflow: unset;
    }

    .container :is(.main-menu, bp-global-header-submenu) {
      height: unset;
      padding: unset;
    }

    .main-menu {
      min-height: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .main-menu :is(a, button) {
      font-size: var(--font-size-sm);
      color: var(--color-gray);
      padding-inline: var(--size-2);
      background-color: transparent !important;
    }

    .container bp-global-header-submenu {
      position: absolute;
      top: var(--size-8);
      left: 50%;
    }

    bp-global-header-submenu :is(a, button) {
      font-size: var(--font-size-lg);
      color: var(--color-black);
      border-radius: var(--radius-md);
      line-height: var(--line-height-normal);
      padding-block: var(--size-1);
      padding-inline: var(--size-1);
    }

    bp-global-header-menu-controls {
      display: none;
    }

    bp-global-header-user-menu {
      --global-header-submenu-tx-opened: 0;
      --global-header-submenu-tx-closed: 0;

      position: absolute;
      inset-block-start: var(--size-11);
      inset-inline-end: var(--size-1);
      padding-block-start: unset;
      opacity: 0;
      transform: translate3d(
        var(--global-header-submenu-tx-closed),
        var(--global-header-submenu-ty-closed),
        0
      );
    }

    bp-global-header-user-menu::before {
      display: none;
      content: unset;
    }

    .simplified > a {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    :host {
      --global-header-logo-width: calc(var(--size-1) * 22.5); // 180px
    }

    .main-menu :is(a, button) {
      font-size: var(--font-size-md);
    }

    bp-global-header-submenu :is(a, button) {
      font-size: var(--font-size-lg);
    }
  }

  @media (hover: hover) {
    .main-menu :is(a, button):hover,
    bp-global-header-submenu :is(a, button):hover {
      background-color: var(--color-neutral-05);
    }
  }
`;
