import { LitElement, css, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { authHrefConverter, clearUserInfo } from "./global-header.helpers";

import type { CurrentUser } from "../../data/user";

@customElement("bp-global-header-user-menu")
export class GlobalHeaderUserMenu extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    nav {
      padding-inline: var(--size-3);
      padding-block-end: var(--size-8);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    a {
      display: flex;
      align-items: center;
      gap: var(--size-1-5);
      width: 100%;
      box-sizing: border-box;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-semibold);
      padding: var(--size-1);
      text-decoration: none;
      color: var(--color-black);
      border-radius: var(--radius-md);
      line-height: var(--line-height-normal);
    }

    @media (min-width: 1024px) {
      nav {
        background-color: var(--color-white);
        border-radius: var(--radius-lg);
        padding: var(--size-5) var(--size-6);
        box-shadow: rgba(24, 29, 54, 0.11) 0px 7px 29px 0px;
        min-width: var(--size-20);
      }

      a {
        padding: var(--size-1);
      }

      .pointer {
        position: absolute;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        inset-block-start: calc(-1 * var(--size-1-5) + 1px);
        inset-inline-end: var(--size-2);
        filter: drop-shadow(rgba(24, 29, 54, 0.03) 0px -2px 4px);
      }

      .pointer::before {
        content: "";
        display: block;
        width: var(--size-6);
        height: var(--size-5);
        background-color: var(--color-white);
        clip-path: polygon(50% 0%, 0 30%, 100% 30%);
      }
    }

    @media (hover: hover) {
      a:hover {
        background-color: var(--color-neutral-05);
      }
    }
  `;

  @property({
    attribute: "href-sign-in",
    converter: authHrefConverter,
  })
  hrefSignIn: string = "#/";

  @property({
    attribute: "href-sign-out",
    converter: authHrefConverter,
  })
  hrefSignOut: string = "#/";

  @property({ attribute: "href-account" })
  hrefAccount: string = "#/";

  @property({ attribute: "href-help-center" })
  hrefHelpCenter: string = "#/";

  @property({ attribute: "label-sign-in" })
  labelSignIn = "";

  @property({ attribute: "label-sign-out" })
  labelSignOut = "";

  @property({ attribute: "label-account" })
  labelAccount = "";

  @property({ attribute: "label-help-center" })
  labelHelpCenter = "";

  @state()
  userInfo: CurrentUser | null = null;

  setUserInfo(info: CurrentUser | null) {
    this.userInfo = info;
  }

  handleSignOutClick = () => {
    clearUserInfo();
  };

  render() {
    return html`
      <nav>
        <div class="pointer"></div>

        <ul>
          ${this.userInfo
            ? html`
                <li>
                  <a href="${this.hrefAccount}">
                    <bp-icon icon="user"></bp-icon>
                    ${this.labelAccount}
                  </a>
                </li>
              `
            : nothing}

          <li>
            <a href="${this.hrefHelpCenter}">
              <bp-icon icon="circle-question"></bp-icon>
              ${this.labelHelpCenter}
            </a>
          </li>

          <li>
            ${this.userInfo
              ? html`
                  <a
                    href="${this.hrefSignOut}"
                    @click="${this.handleSignOutClick}"
                  >
                    <bp-icon icon="arrow-left-to-bracket"></bp-icon>
                    ${this.labelSignOut}
                  </a>
                `
              : html`
                  <a href="${this.hrefSignIn}">
                    <bp-icon icon="arrow-right-from-bracket"></bp-icon>
                    ${this.labelSignIn}
                  </a>
                `}
          </li>
        </ul>
      </nav>
    `;
  }
}
