import { LitElement, css, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { MQL_LG } from "../../constants";
import {
  SUBMENU_CLOSE_EVENT_NAME,
  SUBMENU_OPEN_EVENT_NAME,
  SUBMENU_RESIZE_EVENT_NAME,
} from "./global-header.constants";

@customElement("bp-global-header-submenu")
export class GlobalHeaderSubMenu extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    button {
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      align-items: center;
      gap: var(--size-1);
      width: 100%;
      padding: var(--size-2) var(--size-1);
      border: 0;
      background-color: transparent;
      cursor: pointer;
      color: currentColor;
      display: inline-flex;
      border-radius: var(--radius-md);
    }

    ::slotted(div[slot="footer"]) {
      margin-block-start: var(--size-2);
      position: relative;
    }

    ::slotted(div[slot="footer"])::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      top: calc(-1 * var(--size-1));
      left: 0;
      background-color: var(--color-neutral-10);
    }

    @media (min-width: 1024px) {
      .submenu-container {
        position: relative;
        width: 860px;
      }

      .pointer {
        position: absolute;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        top: calc(-1 * var(--size-1-5) + 1px);
        left: var(
          --global-header-submenu-pointer-offset,
          calc(50% - var(--size-4))
        );
        filter: drop-shadow(rgba(24, 29, 54, 0.03) 0px -2px 4px);
      }

      .pointer::before {
        content: "";
        display: block;
        width: var(--size-6);
        height: var(--size-5);
        background-color: var(--color-white);
        clip-path: polygon(50% 0%, 0 30%, 100% 30%);
      }

      .submenu {
        background-color: var(--color-white);
        border-radius: var(--radius-lg);
        padding: var(--size-3);
        box-shadow: rgba(24, 29, 54, 0.11) 0px 7px 29px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--size-4);
        align-items: center;
        position: relative;
        z-index: 0;
      }

      nav {
        padding-inline: var(--size-3);
      }
    }

    @media (hover: hover) {
      button:hover {
        background-color: var(--color-neutral-05);
      }
    }
  `;

  #desktopMql = MQL_LG;
  #resizeObserver = new ResizeObserver(this.handleResize.bind(this));

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @property({ attribute: "label-back" })
  labelBack: string = "";

  get $items() {
    return this.shadowRoot
      ?.querySelector("slot")
      ?.assignedElements()
      .flatMap((x) => x.querySelectorAll("a"))[0];
  }

  constructor() {
    super();
    this.#resizeObserver.observe(this);

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );
  }

  focusFirstItem() {
    this.$items?.[0].focus();
  }

  open() {
    this.dispatchEvent(
      new CustomEvent(SUBMENU_OPEN_EVENT_NAME, {
        composed: true,
        detail: {
          id: this.id,
        },
      }),
    );
  }

  close() {
    this.dispatchEvent(
      new CustomEvent(SUBMENU_CLOSE_EVENT_NAME, {
        composed: true,
        detail: {
          id: this.id,
        },
      }),
    );
  }

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
  };

  handleResize([entry]: ResizeObserverEntry[]) {
    // Dispatch an event with size info so that the header
    // can determine container sizes.
    this.dispatchEvent(
      new CustomEvent(SUBMENU_RESIZE_EVENT_NAME, {
        composed: true,
        detail: {
          rect: entry.contentRect,
        },
      }),
    );
  }

  handleFooterSlotChange(e: { target: HTMLSlotElement }) {
    const links = e.target
      .assignedElements()
      .flatMap((x) => x.querySelectorAll("a"))[0];

    // Append an arrow icon to each link
    links.forEach((x) => {
      const node = document.createElement("bp-icon");
      node.setAttribute("icon", "arrow-right");
      x.appendChild(node);
    });
  }

  render() {
    return html`
      <div class="submenu-container">
        ${this.useDesktopLayout ? html` <div class="pointer"></div> ` : nothing}

        <div class="submenu">
          ${!this.useDesktopLayout
            ? html`
                <button @click="${this.close}">
                  <bp-icon icon="arrow-left"></bp-icon>
                  ${this.labelBack}
                </button>
              `
            : nothing}

          <nav>
            <slot></slot>

            <slot
              name="footer"
              @slotchange="${this.handleFooterSlotChange}"
            ></slot>
          </nav>

          ${this.useDesktopLayout
            ? html` <slot name="promo"></slot> `
            : nothing}
        </div>
      </div>
    `;
  }
}
