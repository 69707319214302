import { LitElement } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";
import { createFocusTrap } from "focus-trap";

import "./_menu-controls";
import "./_promo";
import "./_language-picker-label";
import "./_search";
import "./_submenu";
import "./_user-menu";
import "./_util-menu";
import { polyfillDeclarativeShadowDOM } from "../../utils/dom";
import { MQL_LG, MQL_MOTION } from "../../constants";
import {
  animateContainerOut,
  animateContainerIn,
  animateMenuOut,
  animateMenuIn,
  animateSearchIn,
  animateSubMenuOut,
  animateSubMenuIn,
  animateBackdropIn,
  animateBackdropOut,
} from "./global-header.animations";
import { styles } from "./global-header.styles";
import { getUserInfo } from "./global-header.helpers";
import {
  MENU_OPEN_EVENT_NAME,
  MENU_CLOSE_EVENT_NAME,
  SUBMENU_OPEN_EVENT_NAME,
  SUBMENU_CLOSE_EVENT_NAME,
  SUBMENU_RESIZE_EVENT_NAME,
  SEARCH_OPEN_EVENT_NAME,
  SEARCH_CLOSE_EVENT_NAME,
  LANGUAGE_TOGGLE_EVENT_NAME,
  USER_MENU_TOGGLE_EVENT_NAME,
} from "./global-header.constants";

import type { FocusTrap, Options } from "focus-trap";
import type { GlobalHeaderSearch } from "./_search";
import type { GlobalHeaderSubMenu } from "./_submenu";
import type { GlobalHeaderUtilMenu } from "./_util-menu";
import type { GlobalHeaderUserMenu } from "./_user-menu";

@customElement("bp-global-header")
export class GlobalHeader extends LitElement {
  static styles = styles;

  // Media Query Lists
  #desktopMql = MQL_LG;
  #motionMql = MQL_MOTION;

  // Focus-related properties
  #focusTrap: FocusTrap | null = null;
  #lastFocusedMenuItem: HTMLButtonElement | null = null;

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @state()
  useAnimations = !this.#motionMql.matches;

  @state()
  mobileMenuMinHeight = 0;

  @query(".container")
  $container?: HTMLElement;

  @query("nav")
  $nav?: HTMLElement;

  @query(".main-menu")
  $mainMenu?: HTMLElement;

  @queryAll(".main-menu button[aria-controls]")
  $mainMenuButtons?: NodeListOf<HTMLButtonElement>;

  @queryAll(".container bp-global-header-submenu")
  $subMenus?: NodeListOf<GlobalHeaderSubMenu>;

  @query("bp-global-header-util-menu")
  $utilMenu?: GlobalHeaderUtilMenu;

  @query("#language-submenu")
  $languageMenu?: HTMLElement;

  @query("bp-global-header-user-menu")
  $userMenu?: GlobalHeaderUserMenu;

  @query("bp-global-header-search")
  $search?: GlobalHeaderSearch;

  @query(".backdrop")
  $backdrop?: HTMLElement;

  get $menuOpenButton() {
    return (
      this.$utilMenu?.shadowRoot?.querySelector<HTMLElement>(
        "button:last-of-type",
      ) ?? undefined
    );
  }

  get $menuCloseButton() {
    return (
      this.$container
        ?.querySelector("bp-global-header-menu-controls")
        ?.shadowRoot?.querySelector<HTMLElement>("button:last-of-type") ??
      undefined
    );
  }

  get animationOptions(): KeyframeAnimationOptions {
    return {
      duration: this.useAnimations ? 500 : 0,
      easing: "cubic-bezier(0.4, 0, 0.2, 1)",
      fill: "forwards",
    };
  }

  ////////////////
  // LIFECYCLE  //
  ////////////////

  constructor() {
    super();
    polyfillDeclarativeShadowDOM(this);
  }

  connectedCallback(): void {
    super.connectedCallback();

    // Hide on initial load to prevent layout shift as the
    // auth check request resolves
    this.style.visibility = "hidden";

    this.addEventListener(
      MENU_OPEN_EVENT_NAME,
      this.handleMenuOpen as EventListener,
    );

    this.addEventListener(
      MENU_CLOSE_EVENT_NAME,
      this.handleMenuClose as EventListener,
    );

    this.addEventListener(
      SUBMENU_OPEN_EVENT_NAME,
      this.handleSubMenuOpen as EventListener,
    );

    this.addEventListener(
      SUBMENU_CLOSE_EVENT_NAME,
      this.handleSubMenuClose as EventListener,
    );

    this.addEventListener(
      SUBMENU_RESIZE_EVENT_NAME,
      this.handleSubMenuResize as EventListener,
    );

    this.addEventListener(
      SEARCH_OPEN_EVENT_NAME,
      this.handleSearchOpen as EventListener,
    );

    this.addEventListener(
      SEARCH_CLOSE_EVENT_NAME,
      this.handleSearchClose as EventListener,
    );

    this.addEventListener(
      LANGUAGE_TOGGLE_EVENT_NAME,
      this.handleLanguageToggle as EventListener,
    );

    this.addEventListener(
      USER_MENU_TOGGLE_EVENT_NAME,
      this.handleUserMenuToggle as EventListener,
    );

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );

    this.#motionMql.addEventListener(
      "change",
      this.handleMotionMediaQueryChange,
    );

    document.addEventListener("keydown", this.handleKeyDown);
  }

  disconnectedCallback() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleDocumentClick);
  }

  firstUpdated(): void {
    this.checkAuth().finally(() => {
      this.style.visibility = "";
    });

    this.reset();

    // Add event handlers
    this.$mainMenuButtons?.forEach((x) => {
      x.addEventListener("click", this.handleMainMenuButtonClick);
    });
  }

  //////////////
  // METHODS  //
  //////////////

  async checkAuth() {
    const info = await getUserInfo();

    this.$utilMenu?.setUserInfo(info);
    this.$userMenu?.setUserInfo(info);
  }

  /**
   * Resets elements to their resting states.
   */
  reset() {
    this.resetA11yAttributes();
    this.closeSearch();
    this.closeAllSubmenus();
    this.updateMenuIcons();
    this.removeBackdrop();

    this.$nav?.removeAttribute("style");
    this.$mainMenu?.removeAttribute("style");
    this.$mainMenu?.removeAttribute("inert");
    this.$subMenus?.forEach((x) => x.removeAttribute("style"));
    this.$userMenu?.removeAttribute("style");
  }

  /**
   * Updates element a11y attributes to the appropriate
   * resting state based on layout mode.
   */
  resetA11yAttributes() {
    if (this.useDesktopLayout) {
      this.$container?.removeAttribute("inert");
      this.$container?.removeAttribute("style");
      this.$userMenu?.setAttribute("inert", "");
    } else {
      this.$container?.setAttribute("inert", "");
      this.$userMenu?.removeAttribute("inert");
    }

    this.$subMenus?.forEach((x) => {
      x.setAttribute("inert", "");
    });

    this.$mainMenuButtons?.forEach((x) => {
      x.setAttribute("aria-haspopup", "true");
      x.setAttribute("aria-expanded", "false");
    });

    this.$search?.deactivate();
  }

  /**
   * Appends icons to menu items with children. Updates the
   * icon if the layout mode changes.
   */
  updateMenuIcons = () => {
    this.$mainMenuButtons?.forEach((x) => {
      const icon =
        x.querySelector("bp-icon") ?? document.createElement("bp-icon");

      icon.setAttribute(
        "icon",
        this.useDesktopLayout ? "angle-down" : "angle-right",
      );

      x.appendChild(icon);
    });
  };

  /**
   * Adds the backdrop element to the document.
   */
  addBackdrop() {
    const node = document.createElement("div");

    node.classList.add("backdrop");

    this.$container?.parentElement?.appendChild(node);

    // Bail if the append failed
    if (!this.$backdrop) return;

    this.$backdrop.addEventListener("click", () => {
      this.closeMenu();
      this.closeSearch();
    });

    animateBackdropIn(this.$backdrop, this.animationOptions);
  }

  /**
   * Removes the backdrop element from the document.
   */
  removeBackdrop() {
    if (!this.$backdrop) return;

    animateBackdropOut(this.$backdrop, this.animationOptions).then(() => {
      this.$backdrop?.remove();
    });
  }

  /**
   * Activates a focus trap.
   */
  activateFocusTrap(element?: HTMLElement | null, options?: Options) {
    // Check that a valid element is provided
    if (!element || element.inert) {
      this.#focusTrap = null;
      return;
    }

    this.#focusTrap = createFocusTrap(
      element,
      Object.assign(
        {
          initialFocus: this.$menuCloseButton,
          setReturnFocus: this.$menuOpenButton,
          allowOutsideClick: true,
          tabbableOptions: {
            getShadowRoot: true,
          },
        },
        options,
      ),
    ).activate();
  }

  /**
   * De-activates the focus trap.
   */
  deactivateFocusTrap() {
    this.#focusTrap?.deactivate();
  }

  /**
   * Open the menu (mobile).
   */
  openMenu() {
    if (this.useDesktopLayout || !this.$container) return;

    this.$container?.removeAttribute("inert");

    animateContainerIn(this.$container, this.animationOptions).then(($el) => {
      $el.style.pointerEvents = "auto";
    });

    this.activateFocusTrap(this.$container);
    this.addBackdrop();
  }

  /**
   * Closes the menu (mobile).
   */
  closeMenu() {
    if (this.useDesktopLayout || !this.$container) return;

    animateContainerOut(this.$container, this.animationOptions).then(() => {
      this.closeAllSubmenus();
      this.closeUserMenu();
      this.resetA11yAttributes();
    });

    this.deactivateFocusTrap();
    this.removeBackdrop();
  }

  /**
   * Opens the specified submenu.
   */
  openSubmenu(
    id: string,
    options?: { $trigger?: HTMLElement; pointerAdjustment?: number },
  ) {
    this.closeAllSubmenus({ exclude: [id] });
    this.closeUserMenu();

    // Animate the parent menus on mobile
    if (!this.useDesktopLayout) {
      [this.$mainMenu, this.$userMenu].forEach((x) => {
        if (!x) return;

        requestAnimationFrame(() => {
          animateMenuOut(x, this.animationOptions).then(() => {
            x.setAttribute("inert", "");
          });
        });
      });
    }

    const $submenu = this.shadowRoot?.querySelector<GlobalHeaderSubMenu>(
      `#${id}`,
    );

    const $parent =
      options?.$trigger ??
      this.shadowRoot?.querySelector<HTMLLIElement>(`[aria-controls="${id}"]`);

    // Bail if the required elements aren't in the DOM
    if (!$parent || !$submenu) return;

    // Calculate the pointer position
    const parentRect = $parent.getBoundingClientRect();
    const submenuRect = $submenu.getBoundingClientRect();

    // Adjust for menu carets
    const adjustment = options?.pointerAdjustment ?? 32;

    const pointerOffset = parentRect.x - submenuRect.x + adjustment;

    $submenu?.style.setProperty(
      "--global-header-submenu-pointer-offset",
      `${pointerOffset}px`,
    );

    $submenu?.removeAttribute("inert");

    // Animate the submenu
    animateSubMenuIn($submenu, this.animationOptions).then(() => {
      if (this.useDesktopLayout) {
        this.activateFocusTrap($submenu, {
          setReturnFocus: $parent!,
        });
      } else {
        $submenu?.focusFirstItem();
      }
    });

    $parent?.setAttribute("aria-expanded", "true");

    document.addEventListener("click", this.handleDocumentClick);
  }

  /**
   * Closes a specific submenu.
   */
  closeSubmenu(id: string, options?: { $trigger?: HTMLElement }) {
    // Animate the top-level menus on mobile
    if (!this.useDesktopLayout) {
      [this.$mainMenu, this.$userMenu].forEach((x) => {
        if (!x) return;

        // Re-enable focus
        x.removeAttribute("inert");

        animateMenuIn(x, this.animationOptions).then(() => {
          // Return focus to the parent
          this.#lastFocusedMenuItem?.focus();
        });
      });
    }

    const $parent =
      options?.$trigger ??
      this.shadowRoot?.querySelector<HTMLLIElement>(`[aria-controls="${id}"]`);

    // Animate the submenu
    const $submenu = this.shadowRoot?.querySelector<GlobalHeaderSubMenu>(
      `#${id}`,
    );

    // Bail if the required elements aren't in the DOM
    if (!$submenu) return;

    animateSubMenuOut($submenu, this.animationOptions).then(() => {
      $submenu.setAttribute("inert", "");
    });

    if (this.useDesktopLayout) {
      this.deactivateFocusTrap();
    }

    $parent?.setAttribute("aria-expanded", "false");

    document.removeEventListener("click", this.handleDocumentClick);
  }

  /**
   * Closes all the submenus.
   */
  closeAllSubmenus(options?: { exclude?: string[] }) {
    this.$subMenus?.forEach((x) => {
      if (!x.inert && !options?.exclude?.includes(x.id)) {
        this.closeSubmenu(x.id);
      }
    });
  }

  /**
   * Opens the search bar.
   */
  openSearch() {
    if (!this.$search) return;

    this.closeAllSubmenus();
    this.closeUserMenu();

    this.$search.activate();

    animateSearchIn(this.$search, this.animationOptions);

    this.activateFocusTrap(this.$search, {
      initialFocus: false,
      setReturnFocus:
        // Re-focus the search icon button
        this.$utilMenu?.shadowRoot?.querySelector<HTMLButtonElement>(
          "nav button:first-of-type",
        ) ?? false,
    });

    this.addBackdrop();
  }

  /**
   * Closes the search bar.
   */
  closeSearch() {
    if (!this.$search) return;

    this.$search.deactivate();
    this.$search.clearResults();
    this.$search.removeAttribute("style");
    this.$search?.reset();

    this.deactivateFocusTrap();
    this.removeBackdrop();
  }

  /**
   * Toggles the language submenu open/closed.
   */
  toggleLanguageMenu($trigger: HTMLElement) {
    const id = "language-submenu";

    const $submenu = this.shadowRoot?.querySelector(
      `#${id}`,
    ) as GlobalHeaderSubMenu;

    if ($submenu.inert) {
      this.openSubmenu(id, {
        $trigger,
        pointerAdjustment: -10,
      });
    } else {
      this.closeSubmenu(id, { $trigger });
    }
  }

  /**
   * Opens the user menu (desktop).
   */
  openUserMenu($trigger?: HTMLElement) {
    if (!this.$userMenu || !this.useDesktopLayout) return;

    this.closeAllSubmenus();

    this.$userMenu.removeAttribute("inert");

    animateSubMenuIn(this.$userMenu, this.animationOptions).then(() => {
      this.activateFocusTrap(this.$userMenu, {
        setReturnFocus: $trigger,
      });
    });

    document.addEventListener("click", this.handleDocumentClick);
  }

  /**
   * Closes the user menu (desktop).
   */
  closeUserMenu() {
    if (!this.$userMenu || !this.useDesktopLayout) return;

    animateSubMenuOut(this.$userMenu, this.animationOptions).then(() => {
      this.$userMenu?.setAttribute("inert", "");
    });

    this.deactivateFocusTrap();

    document.removeEventListener("click", this.handleDocumentClick);
  }

  ///////////////
  // HANDLERS  //
  ///////////////

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
    this.reset();
  };

  handleMotionMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useAnimations = !matches;
  };

  handleMenuOpen = (e: CustomEvent) => {
    e.stopPropagation();
    this.openMenu();
  };

  handleMenuClose = (e: CustomEvent) => {
    e.stopPropagation();
    this.closeMenu();
  };

  handleMainMenuButtonClick = (e: MouseEvent) => {
    try {
      // Determine the target submenu
      const target = e.currentTarget as HTMLButtonElement;
      const controls = target.getAttribute("aria-controls");
      const $submenu = this.shadowRoot?.querySelector(
        `#${controls}`,
      ) as GlobalHeaderSubMenu;

      if ($submenu.inert) {
        this.openSubmenu($submenu.id);

        // Save the clicked menu item so it can be
        // re-focused after the menu closes. This is
        // required because the menu can be closed my
        // several different triggers, but we only want to
        // return focus to the parent menu item.
        this.#lastFocusedMenuItem = target;
      } else {
        this.closeSubmenu($submenu.id);
      }
    } catch (error) {
      console.log("Failed to open submenu.");
    }
  };

  handleSubMenuResize = (e: CustomEvent<{ rect: DOMRectReadOnly }>) => {
    if (this.useDesktopLayout) return;

    const { height } = e.detail.rect;

    // Capture the tallest submenu height to use as the
    // min-height on the entire nav. This ensures that the
    // mobile nav doesn't shift when submenus open/close.
    if (height > this.mobileMenuMinHeight) {
      this.mobileMenuMinHeight = height;

      if (this.$nav) {
        this.$nav.style.setProperty(
          "--global-header-menu-min-height",
          `${this.mobileMenuMinHeight}px`,
        );
      }
    }
  };

  handleSubMenuOpen = (e: CustomEvent<{ id: string }>) => {
    e.stopPropagation();
    this.openSubmenu(e.detail.id);
  };

  handleSubMenuClose = (e: CustomEvent<{ id: string }>) => {
    e.stopPropagation();
    this.closeSubmenu(e.detail.id);
  };

  handleSearchOpen = (e: CustomEvent) => {
    e.stopPropagation();
    this.openSearch();
  };

  handleSearchClose = (e: CustomEvent) => {
    e.stopPropagation();
    this.closeSearch();
  };

  handleLanguageToggle = (e: CustomEvent<{ $trigger: HTMLElement }>) => {
    e.stopPropagation();
    this.toggleLanguageMenu(e.detail.$trigger);
  };

  handleUserMenuToggle = (e: CustomEvent<{ $trigger: HTMLElement }>) => {
    e.stopPropagation();

    if (this.$userMenu?.inert) {
      this.openUserMenu(e.detail.$trigger);
    } else {
      this.closeUserMenu();
    }
  };

  handleDocumentClick = (e: MouseEvent) => {
    if (!this.useDesktopLayout) return;

    const shouldClose =
      e.composedPath()[0] === this.$container ||
      !e
        .composedPath()
        .find((x) => x === this.$container || x === this.$utilMenu);

    if (shouldClose) {
      this.closeAllSubmenus();
      this.closeUserMenu();
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        if (this.useDesktopLayout) {
          this.closeAllSubmenus();
          this.closeUserMenu();
        } else {
          this.closeMenu();
        }
        return;
      default:
        return;
    }
  };
}

declare global {
  interface HTMLElementTagNameMap {
    "bp-global-header": GlobalHeader;
  }
}
