export async function animateContainerIn(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 1,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateContainerOut(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 0,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateMenuIn(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  $element
    .animate(
      [
        {
          opacity: 1,
          transform: `
            translate3d(
              var(--global-header-submenu-tx-opened),
              var(--global-header-submenu-ty-opened),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateMenuOut(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 0,
          transform: `
            translate3d(
              var(--global-header-menu-tx-closed),
              var(--global-header-menu-ty-closed),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateSubMenuIn(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 1,
          transform: `
            translate3d(
              var(--global-header-submenu-tx-opened),
              var(--global-header-submenu-ty-opened),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateSubMenuOut(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 0,
          transform: `
            translate3d(
              var(--global-header-submenu-tx-closed),
              var(--global-header-submenu-ty-closed),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateSearchIn(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          transform: `
            translate3d(
              0,
              var(--global-header-search-ty-opened),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateSearchOut(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          transform: `
            translate3d(
              0,
              var(--global-header-search-ty-closed),
              0
            )
          `,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateBackdropIn(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    .animate(
      [
        {
          opacity: 1,
        },
      ],
      options,
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}

export async function animateBackdropOut(
  $element: HTMLElement,
  options: KeyframeAnimationOptions,
) {
  await $element
    ?.animate(
      [
        {
          opacity: 0,
        },
      ],
      {
        ...options,
        duration: 300,
      },
    )
    .finished.then((animation) => {
      animation.commitStyles();
      animation.cancel();
    });

  return $element;
}
