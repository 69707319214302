import { get } from "../modules/env";

export interface CurrentUser {
  avatar: string | null;
}

/**
 * Returns the current user's info.
 */
export async function getCurrentUser(): Promise<CurrentUser | null> {
  const url = get("USER_API_URL") ?? globalThis.document.location.origin;

  // TODO Replace this with a Zed query once the avatar
  // is added to the Profile object.
  const data = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-October-Request-Handler": "AsyncData::onGetUserData",
      "X-Requested-With": "XMLHttpRequest",
    },
  }).then(async (x) => await x.json());

  const parser = new DOMParser();
  const doc = parser.parseFromString(data.avatar, "text/html");

  if (!data.logged_in) {
    return null;
  }

  return {
    avatar: doc.querySelector("img")?.src ?? null,
  };
}

/**
 * Adds the provided email address to a newsletter
 * distribution.
 */
export async function newsletterSignUp(
  email: string,
  options: { handler: string },
) {
  const body = new URLSearchParams([
    ["email", email],
    ["handler", options.handler],
  ]);

  const url = get("EMAIL_SIGN_UP_URL") as string;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body,
  });

  return res.ok;
}
