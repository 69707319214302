import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("bp-global-header-promo")
export class GlobalHeaderPromo extends LitElement {
  static styles = css`
    :host {
      --global-header-promo-size: 420px;

      display: block;
      height: var(--global-header-promo-size);
      width: var(--global-header-promo-size);
    }

    .promo {
      position: relative;
      text-decoration: none;
      border-radius: var(--radius-md);
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--color-neutral-10);
    }

    .promo::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(
        0deg,
        rgba(1, 1, 1, 0.5) 10.57%,
        rgba(1, 1, 1, 0) 72.42%
      );
    }

    .image ::slotted(*) {
      position: absolute;
      inset: 0;
      z-index: 0;
      object-fit: cover;
      object-position: center center;
    }

    .text {
      position: absolute;
      inset-block-end: 0;
      z-index: 1;
      color: var(--color-white);
      padding: var(--size-3);
      line-height: var(--line-height-snug);
    }

    .pretitle {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-md);
    }

    .title {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-xl);
      display: flex;
      align-items: center;
      gap: var(--size-1);
    }

    .title bp-icon {
      margin-block-end: -2px;
    }
  `;

  @property()
  href? = undefined;

  render() {
    return html`
      <a class="promo" href="${this.href}">
        <div class="image">
          <slot name="image"></slot>
        </div>

        <div class="text">
          <div class="pretitle">
            <slot name="pretitle"></slot>
          </div>

          <div class="title">
            <slot name="title"></slot>
            <bp-icon icon="arrow-right"></bp-icon>
          </div>
        </div>
      </a>
    `;
  }
}
