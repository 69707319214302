export const LANGUAGE_TOGGLE_EVENT_NAME = "bp:global-header:language-toggle";
export const MENU_CLOSE_EVENT_NAME = "bp:global-header:menu-close";
export const MENU_OPEN_EVENT_NAME = "bp:global-header:menu-open";
export const SEARCH_CLOSE_EVENT_NAME = "bp:global-header:search-close";
export const SEARCH_OPEN_EVENT_NAME = "bp:global-header:search-open";
export const SUBMENU_CLOSE_EVENT_NAME = "bp:global-header:submenu-close";
export const SUBMENU_OPEN_EVENT_NAME = "bp:global-header:submenu-open";
export const SUBMENU_RESIZE_EVENT_NAME = "bp:global-header:submenu-resize";
export const USER_INFO_STORAGE_KEY = "bp:global-header:user-info";
export const USER_MENU_TOGGLE_EVENT_NAME = "bp:global-header:user-menu-toggle";
