import { LitElement, css, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { MQL_LG } from "../../constants";
import {
  LANGUAGE_TOGGLE_EVENT_NAME,
  MENU_OPEN_EVENT_NAME,
  SEARCH_OPEN_EVENT_NAME,
  USER_MENU_TOGGLE_EVENT_NAME,
} from "./global-header.constants";
import { authHrefConverter } from "./global-header.helpers";

import type { CurrentUser } from "../../data/user";

@customElement("bp-global-header-util-menu")
export class GlobalHeaderUtilMenu extends LitElement {
  static styles = css`
    :host {
      display: block;
    }

    nav {
      display: flex;
      gap: var(--size-1-5);
      align-items: center;
    }

    .button {
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      color: currentColor;
      cursor: pointer;
      display: inline-flex;
      font-family: var(--font-sans);
      gap: var(--size-1);
      justify-content: space-between;
      line-height: var(--line-height-none);
      margin: 0;
      padding: var(--size-0-5);
      text-align: start;
      text-decoration: none;
      -webkit-user-select: none;
      user-select: none;
    }

    [role="separator"] {
      height: var(--size-3);
      width: 1px;
      background-color: var(--color-neutral-20);
    }

    .sign-in {
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      color: currentColor;
      cursor: pointer;
      font-family: var(--font-sans);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-semibold);
      gap: var(--size-1);
      line-height: var(--line-height-none);
      margin: 0;
      padding: var(--size-1);
      text-align: start;
      text-decoration: none;
      border-radius: var(--radius-lg);
      border: 2px solid currentColor;
    }

    .avatar {
      display: block;
      border: 0;
      padding: 0;
      width: var(--size-4);
      height: var(--size-4);
      overflow: hidden;
      border-radius: var(--radius-full);
      cursor: pointer;
    }

    .avatar > img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  `;

  #desktopMql = MQL_LG;

  @property({ attribute: "href-bible-reader" })
  hrefBibleReader: string = "";

  @property({
    attribute: "href-sign-in",
    converter: authHrefConverter,
  })
  hrefSignIn: string = "#/";

  @property({ attribute: "label-search" })
  labelSearch: string = "";

  @property({ attribute: "label-bible-reader" })
  labelBibleReader: string = "";

  @property({ attribute: "label-language-picker" })
  labelLanguagePicker: string = "";

  @property({ attribute: "label-sign-in" })
  labelSignIn: string = "";

  @property({ attribute: "label-menu" })
  labelMenu: string = "";

  @property({ attribute: "label-user-menu" })
  labelUserMenu: string = "";

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @state()
  userInfo: CurrentUser | null = null;

  setUserInfo(info: CurrentUser | null) {
    this.userInfo = info;
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.#desktopMql.addEventListener("change", this.handleMediaQueryChange);
  }

  handleMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
  };

  handleMenuClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(MENU_OPEN_EVENT_NAME, {
        composed: true,
      }),
    );
  };

  handleSearchClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(SEARCH_OPEN_EVENT_NAME, {
        composed: true,
      }),
    );
  };

  handleLanguageClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(LANGUAGE_TOGGLE_EVENT_NAME, {
        composed: true,
        detail: {
          $trigger: e.currentTarget,
        },
      }),
    );
  };

  handleAvatarClick = (e: MouseEvent) => {
    e.preventDefault();

    this.dispatchEvent(
      new CustomEvent(USER_MENU_TOGGLE_EVENT_NAME, {
        composed: true,
        detail: {
          $trigger: e.currentTarget,
        },
      }),
    );
  };

  renderButtons() {
    if (this.useDesktopLayout) {
      return html`
        <button
          class="button"
          aria-label="${this.labelSearch}"
          @click="${this.handleSearchClick}"
        >
          <bp-icon icon="magnifying-glass"></bp-icon>
        </button>

        ${this.hrefBibleReader
          ? html`
              <a
                href="${this.hrefBibleReader}"
                class="button"
                aria-label="${this.labelBibleReader}"
              >
                <bp-icon icon="book-open"></bp-icon>
              </a>
            `
          : nothing}

        <button
          class="button"
          aria-label="${this.labelLanguagePicker}"
          @click="${this.handleLanguageClick}"
        >
          <bp-icon icon="globe"></bp-icon>
        </button>
      `;
    }

    return html`
      <button
        class="button"
        aria-label="${this.labelSearch}"
        @click="${this.handleSearchClick}"
      >
        <bp-icon icon="magnifying-glass"></bp-icon>
      </button>
    `;
  }

  renderAnchor() {
    if (this.useDesktopLayout) {
      if (this.userInfo?.avatar) {
        return html`
          <button
            class="avatar"
            @click="${this.handleAvatarClick}"
            aria-label="${this.labelUserMenu}"
          >
            <img src="${this.userInfo.avatar}" alt="" />
          </button>
        `;
      }

      return html`
        <button
          class="button"
          @click="${this.handleAvatarClick}"
          aria-label="${this.labelUserMenu}"
        >
          <bp-icon icon="circle-user" size="lg"></bp-icon>
        </button>
      `;
    }

    return html`
      <button
        aria-label="${this.labelMenu}"
        class="button"
        @click="${this.handleMenuClick}"
      >
        <bp-icon icon="bars-staggered" size="lg"></bp-icon>
      </button>
    `;
  }

  render() {
    return html`
      <nav>
        ${this.renderButtons()}
        <div role="separator"></div>
        ${this.renderAnchor()}
      </nav>
    `;
  }
}
