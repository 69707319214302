import { LitElement, css, html, nothing } from "lit";
import {
  customElement,
  property,
  query,
  queryAll,
  state,
} from "lit/decorators.js";

import { getNextFocusIndex } from "../../utils/a11y";
import { MQL_LG } from "../../constants";
import { debounce } from "../../utils/debounce";
import { performSearch, type SearchResultData } from "./global-header.helpers";
import {
  SEARCH_CLOSE_EVENT_NAME,
  SEARCH_OPEN_EVENT_NAME,
} from "./global-header.constants";

@customElement("bp-global-header-search")
export class GlobalHeaderSearch extends LitElement {
  static styles = css`
    :host {
      --global-header-search-max-width: calc(
        var(--global-header-max-width) * 0.8
      );

      display: block;
    }

    .search {
      position: relative;
      background-color: var(--color-white);
      padding-inline: max(
        var(--size-4),
        50vw - var(--global-header-search-max-width) / 2
      );
      padding-block: var(--size-12) var(--size-5);
    }

    .form {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: var(--size-3);
    }

    button {
      border: 0;
      background: transparent;
      appearance: none;
      display: inline-flex;
      align-items: center;
      gap: var(--size-1);
      font-family: var(--font-sans);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-semibold);
      user-select: none;
      cursor: pointer;
      color: currentColor;
      padding: var(--size-0-5);
    }

    button:disabled {
      filter: grayscale(100%);
      opacity: var(--opacity-50);
      pointer-events: none;
    }

    .close-button {
      position: absolute;
      inset-block-start: calc(var(--size-4) + var(--size-0-5));
      inset-inline-end: var(--size-3);
    }

    .search-button {
      padding-inline-end: 0;
    }

    input {
      border: 0;
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-2xl);
      line-height: var(--line-height-tight);
      appearance: none;
      background-color: transparent;
      padding: 0;
      min-width: 0;
    }

    input:focus {
      outline: none;
    }

    input::-webkit-search-cancel-button {
      appearance: none;
    }

    .results {
      margin-block-start: var(--size-4);
      display: grid;
      gap: var(--size-2);
    }

    .results .predictions {
      display: flex;
      align-items: center;
      border-block-end: var(--border);
      padding-block-end: var(--size-2);
    }

    .results .items a {
      text-decoration: none;
      font-weight: var(--font-weight-semibold);
      color: currentColor;
    }

    .results ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .results li {
      border-radius: var(--radius-md);
      cursor: pointer;
    }

    .results li[aria-selected="true"] {
      background-color: var(--color-neutral-05);
    }

    .results > .predictions li,
    .results a {
      display: block;
      padding: var(--size-1);
    }

    .label {
      text-transform: uppercase;
      letter-spacing: var(--letter-spacing-wide);
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-sm);
      color: var(--color-gray);
      margin: var(--size-1);
      margin-block-end: var(--size-2);
    }

    @media (min-width: 1024px) {
      .search {
        padding-block: var(--size-13) var(--size-10);
      }

      .results {
        grid-template-columns: min(var(--size-30)) 1fr;
      }

      .results > .predictions {
        border-block-end: unset;
        padding-block-end: unset;
        border-inline-end: var(--border);
        padding-inline-end: var(--size-2);
      }
    }

    @media (hover: hover) {
      .results li:hover {
        background-color: var(--color-neutral-05);
      }
    }
  `;

  #desktopMql = MQL_LG;

  @state()
  useDesktopLayout = this.#desktopMql.matches;

  @state()
  data: SearchResultData | null = null;

  @property({ attribute: "placeholder-short" })
  placeholderShort = "";

  @property({ attribute: "placeholder-long" })
  placeholderLong = "";

  @property({ attribute: "label-close" })
  labelClose = "";

  @property({ attribute: "label-search" })
  labelSearch = "";

  @property({ attribute: "label-results" })
  labelResults = "";

  @query("form")
  $form?: HTMLFormElement;

  @query("input")
  $input?: HTMLInputElement;

  @queryAll(".results li")
  $resultsListItems?: NodeListOf<HTMLElement>;

  _activeItemIndex = -1;

  get activeItemIndex() {
    return this._activeItemIndex;
  }

  set activeItemIndex(newVal: number) {
    this._activeItemIndex = newVal;

    this.$resultsListItems?.forEach((x, i) => {
      if (i === newVal) {
        x.setAttribute("aria-selected", "true");

        // Set the input value to the text of the
        // highlighted item
        if (this.$input && x.textContent) {
          this.$input.value = x.textContent?.trim();
        }
      } else {
        x.removeAttribute("aria-selected");
      }
    });
  }

  get placeholder() {
    return this.useDesktopLayout ? this.placeholderLong : this.placeholderShort;
  }

  get hasResults() {
    return this.data?.predictions.length || this.data?.items.length;
  }

  constructor() {
    super();

    this.#desktopMql.addEventListener(
      "change",
      this.handleDesktopMediaQueryChange,
    );

    this.addEventListener("keydown", this.handleKeyDown as EventListener);
  }

  activate() {
    this.removeAttribute("inert");
    this.$input?.focus();
  }

  deactivate() {
    this.setAttribute("inert", "");
  }

  reset() {
    this.$form?.reset();
    this.clearResults();
  }

  clearResults() {
    this.activeItemIndex = -1;
    this.data = null;
  }

  open() {
    this.activate();

    this.dispatchEvent(
      new CustomEvent(SEARCH_OPEN_EVENT_NAME, {
        composed: true,
      }),
    );
  }

  close() {
    this.deactivate();

    this.dispatchEvent(
      new CustomEvent(SEARCH_CLOSE_EVENT_NAME, {
        composed: true,
      }),
    );
  }

  async performSearch(query: string) {
    this.activeItemIndex = -1;
    this.data = await performSearch(query);
  }

  gotoResultsPage(query: string) {
    const href = `/search/#?cludoquery=${query}`;

    window.location.assign(href);
  }

  focusNextResult() {
    if (!this.data) return;

    this.activeItemIndex = getNextFocusIndex(
      "next",
      this.activeItemIndex,
      this.$resultsListItems?.length ?? 0,
    );
  }

  focusPrevResult() {
    if (!this.data) return;

    this.activeItemIndex = getNextFocusIndex(
      "prev",
      this.activeItemIndex,
      this.$resultsListItems?.length ?? 0,
    );
  }

  handleDesktopMediaQueryChange = ({ matches }: MediaQueryListEvent) => {
    this.useDesktopLayout = matches;
  };

  handleCloseButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    this.close();
  };

  handleInput = debounce(() => {
    const value = this.$input?.value;

    if (value) {
      this.performSearch(value);
    } else {
      this.clearResults();
    }
  }, 250);

  handlePredictionItemClick = (e: MouseEvent) => {
    // Find the item's index in the results list
    const query = Array.from(this.$resultsListItems ?? [])
      .find((x) => x === e.currentTarget)
      ?.textContent?.trim();

    if (query) {
      this.gotoResultsPage(query);
    }
  };

  handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();

    const $item = this.$resultsListItems?.item(this.activeItemIndex);
    const href = $item?.querySelector("a")?.getAttribute("href");

    // Follow the link if the item is a link
    if (href) {
      window.location.assign(href);
      return;
    }

    const query = this.$input?.value;

    // Use the input value as the query for the search page
    if (query) {
      this.gotoResultsPage(query);
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Tab":
        this.activeItemIndex = -1;
        return;
      case "Escape":
        this.close();
        return;
      case "ArrowDown":
        e.preventDefault();
        this.focusNextResult();
        return;
      case "ArrowUp":
        e.preventDefault();
        this.focusPrevResult();
        return;
      default:
        return;
    }
  };

  renderPredictions() {
    if (!this.data?.predictions.length) {
      return nothing;
    }

    return html`
      <ul role="listbox">
        ${this.data?.predictions.map(
          (x) => html`
            <li role="option" @click="${this.handlePredictionItemClick}">
              ${x}
            </li>
          `,
        )}
      </ul>
    `;
  }

  renderLinks() {
    if (!this.data?.items.length) {
      return nothing;
    }

    return html`
      <div class="items">
        <div class="label">${this.labelResults}</div>

        <ul role="listbox">
          ${this.data?.items.map(
            (x) => html`
              <li role="option">
                <a href="${x.href}">${x.text}</a>
              </li>
            `,
          )}
        </ul>
      </div>
    `;
  }

  render() {
    return html`
      <div class="search">
        <form class="form" @submit="${this.handleSubmit}">
          <bp-icon icon="magnifying-glass"></bp-icon>

          <input
            type="search"
            enterkeyhint="search"
            autocomplete="off"
            autofocus
            aria-autocomplete="list"
            placeholder="${this.placeholder}"
            @input="${this.handleInput}"
          />

          <button
            class="search-button"
            type="submit"
            ?disabled="${!this.$input?.value}"
          >
            ${this.labelSearch}
            <bp-icon icon="arrow-right"></bp-icon>
          </button>
        </form>

        <button
          aria-label="${this.labelClose}"
          class="close-button"
          @click=${this.handleCloseButtonClick}
        >
          <bp-icon icon="xmark" size="lg"></bp-icon>
        </button>

        ${this.hasResults
          ? html`
              <div class="results">
                <div class="predictions">${this.renderPredictions()}</div>
                <div class="items">${this.renderLinks()}</div>
              </div>
            `
          : nothing}
      </div>
    `;
  }
}
