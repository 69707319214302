/**
 * Gets the index value of the next focusable item
 * in a set of focusable items (e.g. menu)
 */
export function getNextFocusIndex(
  direction: "prev" | "next",
  currentIndex: number,
  itemCount: number,
) {
  if (!itemCount) return 0;

  if (direction === "prev") {
    const nextIndex = currentIndex - 1;
    return nextIndex < 0 ? itemCount - 1 : nextIndex;
  }

  if (direction === "next") {
    const nextIndex = currentIndex + 1;
    return nextIndex < itemCount ? nextIndex : 0;
  }

  throw new Error("direction not supported.");
}
